import scenarioBtn from "@/components/buttons/scenarioBtn/scenarioBtn.vue";
export default {
  name: "displayScenariosBtn",
  components: {
    scenarioBtn
  },
  props: {
    scenarios: {
      type: Array,
      default: []
    }
  },
  methods: {
    goToScenario(section) {
      this.$router.push({
        name: "Chapter-three",
        query: { q: section, step: 1 }
      });
    }
  }
};
