import appHeader from "@/components/Header/header.vue";
import plainBtn from "@/components/buttons/plainBtn/plainBtn.vue";
import successiveQuestions from "@/components/successiveQuestions/successiveQuestions.vue";
import displayScenariosBtn from "@/components/displayScenariosBtn/displayScenariosBtn.vue";
import { mapGetters } from "vuex";
import chapter3 from "@/json/chapter3.json";

export default {
  name: "chapterThree",
  components: {
    appHeader,
    plainBtn,
    successiveQuestions,
    displayScenariosBtn
  },
  props: {
    section: {
      default: null
    },
    step: {
      default: null
    },
    showSynthesis: {
      default: false
    }
  },
  data() {
    return {
      json: chapter3,
      isLoaded: false,
      inScenario: false
    };
  },
  async mounted() {
    await this.$store.dispatch("initScenarioExercice", chapter3.properties);
    if (this.section !== null && this.step !== null) {
      await this.$store.dispatch("setActualScenario", this.section);
      await this.$store.dispatch("setActualScenarioStep", this.step - 1);
    }
    this.isLoaded = true;
  },
  computed: {
    // parsed word index
    // use this instead of props wordIndex
    parsedSection() {
      return parseInt(this.section);
    },
    parsedStep() {
      return parseInt(this.step);
    },
    castedShowSynthesis() {
      return this.showSynthesis === "true";
    },
    instruction() {
      return this.actualScenarioStep
        ? this.actualScenarioStep.instruction
        : this.json.instruction;
    },
    goodAnswers() {
      return this.actualSuccessiveStep
        ? this.actualSuccessiveStep["good-answer"]
        : null;
    },
    availableAnswers() {
      return this.actualScenarioStep
        ? this.actualScenarioStep["available-answers"]
        : null;
    },
    goodAnswer() {
      return this.actualScenarioStep
        ? this.actualScenarioStep["good-answer"]
        : null;
    },
    stepSynthesis() {
      return this.actualScenarioStep
        ? this.actualScenarioStep.synthesisText
        : null;
    },
    stepSynthesisDiagram() {
      return this.actualScenarioStep
        ? this.actualScenarioStep.diagram
        : null;
    },
    progress() {
      if (this.parsedStep) {
        if (this.isLastSynthesis) {
          return 100;
        }
        return ((this.parsedStep - 1) * 100) / this.numberOfScenarioSteps;
      }
      return 100;
    },
    isLastSection() {
      return this.parsedStep === this.numberOfScenarioSteps;
    },
    isLastSynthesis() {
      return this.isLastSection && this.castedShowSynthesis;
    },
    ...mapGetters([
      "availableScenarios",
      "actualScenarioStep",
      "numberOfScenarioSteps"
    ])
  },
  methods: {
    async goToSynthesis() {
      if (this.stepSynthesis) {
        this.$router.push({
          name: "Chapter-three",
          query: { q: this.parsedSection, step: this.parsedStep, s: "true" }
        });
      } else {
        this.nextSection();
      }
    },
    nextSection() {
      if (this.isLastSection) {
        this.$router.push({
          name: "Home"
        });
        this.$store.dispatch("resetScenario");
      } else {
        this.$router.push({
          name: "Chapter-three",
          query: { q: this.parsedSection, step: this.parsedStep + 1 },
          append: true
        });
      }
    }
  },
  watch: {
    async section(actual) {
      await this.$store.dispatch("setActualScenario", actual);
      await this.$store.dispatch("setActualScenarioStep", this.step - 1);
    },
    async step(actual) {
      await this.$store.dispatch("setActualScenarioStep", actual - 1);
    }
  }
};
