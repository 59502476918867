import openPoso from "@/components/icons/openPoso";
export default {
  name: "scenarioBtn",
  components: {
    openPoso
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    picture: {
      type: String,
      default: null
    }
  },
  computed: {
    formatedPicture() {
      return {
        src: require(`@/assets/${this.picture}`),
        description: this.title
      };
    }
  },
  methods: {
    goToScenario() {
      this.$emit("goToScenario");
    }
  }
};
